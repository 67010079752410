import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioContent = ({ audioTitles, audioLinks }) => {
  const clips = (audio) => {
    if (!audioTitles || !audioLinks) {
      return null;
    }

    let audioPlayers = [];

    for (let i = 0; i < audioTitles.length; i++) {
      audioPlayers.push(
        <div key={i} style={{ padding: "1rem 0" }}>
          <div className="title has-text-primary is-size-5">
            {audioTitles[i]}
          </div>
          <div style={{ width: "100%" }}>
            <AudioPlayer src={audioLinks[i]} />
          </div>
        </div>
      );
    }

    return <div>{audioPlayers}</div>;
  };

  return clips(audioTitles, audioLinks);
};

export default AudioContent;
